import React from "react";

const Broadcast = () => {
  return (
    <div className="bg-gray-700 w-screen h-screen flex flex-col justify-center items-center p-4">
      <div className="bg-black bg-opacity-30 rounded-lg shadow-md p-4 flex flex-col items-center justify-center">
        <img src="https://via.placeholder.com/50" alt="Placeholder" />
        <h2 className="text-xl font-semibold mb-2 text-gray-100 uppercase">
          Welcome, Calvin
        </h2>
      </div>
    </div>
  );
};

export default Broadcast;
