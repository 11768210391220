import React, { useState } from "react";
import Swal from "sweetalert2";
import AdiraBG from "../assets/inputabsen/BG.png";
import SumpahGardira from "../assets/inputabsen/sumpahgardira.png";
import BtnKirim from "../assets/inputabsen/btn_kirim.png";
import LogoAdira from "../assets/statistik/PNG/logoadira.png";
import Grofit from "../assets/inputabsen/grofit.png";
import Adira from "../assets/inputabsen/adira.png";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    area: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Convert the input value to title case
    const capitalizedValue = value
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
    setFormData({
      ...formData,
      [name]: capitalizedValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Establish a WebSocket connection
    const ws = new WebSocket("wss://adira-api.midyear.mahakreasi.com");

    // Handle WebSocket connection open event
    ws.onopen = () => {
      console.log("WebSocket connection established.");

      // Prepare data object
      const data = {
        type: "register",
        data: {
          name: formData.name,
          area: formData.area,
        },
      };

      // Send data as JSON string
      ws.send(JSON.stringify(data));
    };

    // Handle WebSocket message event
    ws.onmessage = (event) => {
      console.log("Message from server:", event.data);
      // You can handle server responses here if needed
      // For example, display a success message using SweetAlert2
      Swal.fire({
        title: "Success!",
        text: "Thank you for your submission. Your form has been submitted successfully.",
        icon: "success",
      });

      // Reset the form fields after submission
      setFormData({
        name: "",
        area: "",
      });

      // Close the WebSocket connection
      ws.close();
    };

    // Handle WebSocket error event
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      // Handle any errors, such as displaying an error message
    };

    // Handle WebSocket connection close event
    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };
  };

  return (
    <div className="font-racesport w-screen h-screen flex items-end pb-28 justify-center p-4">
      <img
        className="absolute top-0 left-0 z-0 w-screen h-screen object-cover"
        src={AdiraBG}
        alt=""
      />
      <img
        className="absolute top-[-8px] left-0 w-16 m-4"
        src={Grofit}
        alt=""
      />
      <img className="absolute top-0 right-0 w-16 m-4" src={Adira} alt="" />
      <div className="w-screen z-10 relative space-y-5">
        <div className="px-3 flex flex-col items-center space-y-16">
          <img className="w-28" src={LogoAdira} alt="" />
          <img
            src={SumpahGardira}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-4 bg-black p-4">
              <div className="">
                <label
                  htmlFor="name"
                  className="block text-gray-100 text-center text-md font-medium"
                >
                  NAMA
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  autoComplete="name"
                  required
                  className="mt-1 p-2 block w-full border-gray-300 border-2 bg-yellow-400 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-300 focus:ring-opacity-50 capitalize"
                />
              </div>
              <div className="">
                <label
                  htmlFor="area"
                  className="block text-gray-100 text-center text-md font-medium"
                >
                  WILAYAH
                </label>
                <select
                  id="area"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                  autoComplete="area"
                  required
                  className="mt-1 p-2 block w-full border-gray-300 border-2 bg-yellow-400 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-300 focus:ring-opacity-50"
                >
                  <option value=""></option>
                  <option value="Sumbagut">Sumbagut</option>
                  <option value="Sumbagsel">Sumbagsel</option>
                  <option value="Jabotabek 1">Jabotabek 1</option>
                  <option value="Jabotabek 2">Jabotabek 2</option>
                  <option value="Jawa Barat">Jawa Barat</option>
                  <option value="Jawa Tengah">Jawa Tengah</option>
                  <option value="BNT">BNT</option>
                  <option value="Kalimantan">Kalimantan</option>
                  <option value="Pasima">Pasima</option>
                  <option value="Sultan">Sultan</option>
                  <option value="Head Office">Head Office</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="rounded-md font-medium hover:bg-yellow-400 transition duration-300"
              >
                <img className="w-40" src={BtnKirim} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
