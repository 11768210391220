import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import LoadingScreen from "./layouts/Loading";
import Register from "./layouts/Register";
import Statistic from "./layouts/Statistic";
import Broadcast from "./layouts/Broadcast";
import Maxperson from "./layouts/Maxperson";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Routes>
          <Route path="/" element={<Statistic />} />
          <Route path="/Maxperson" exact element={<Maxperson />} />
          <Route path="/Broadcast" element={<Broadcast />} />
          <Route path="/Register" element={<Register />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
