import React, { useState, useEffect } from "react";
import Background from "../assets/statistik/PNG/bgstatistic.png";
import StatistikKehadiran from "../assets/statistik/PNG/statistikkehadiran.png";
import LogoAdira from "../assets/statistik/PNG/logoadira.png";

function Statistic() {
  const [dataArea, setDataArea] = useState([]);
  // const [Sumbagutpercentage, setPercentageSumbagut] = useState(0);
  // const [Sumbagtengpercentage, setPercentageSumbagteng] = useState(0);
  // const [Sumbagselpercentage, setPercentageSumbagsel] = useState(0);
  // const [Jabotabek1percentage, setPercentageJabotabek1] = useState(0);
  // const [Jabotabek2percentage, setPercentageJabotabek2] = useState(0);
  // const [JawaBaratpercentage, setPercentageJawaBarat] = useState(0);
  // const [JawaTengahpercentage, setPercentageJawaTengah] = useState(0);
  // const [JawaTimurpercentage, setPercentageJawaTimur] = useState(0);
  // const [BNTpercentage, setPercentageBNT] = useState(0);
  // const [Kalimantanpercentage, setPercentageKalimantan] = useState(0);
  // const [Pasimapercentage, setPercentagePasima] = useState(0);
  // const [Sultanpercentage, setPercentageSultan] = useState(0);
  // const [HeadOfficepercentage, setPercentageHeadOffice] = useState(0);

  // useEffect(() => {
  //   ws.onopen = () => {
  //     console.log("Connected to WebSocket server");

  //     // Function to send the request data to the server
  //     const sendRequest = () => {
  //       // Prepare the request data object
  //       const requestData = {
  //         type: "getAreaData",
  //         data: {},
  //       };

  //       // Send the request data to the server
  //       ws.send(JSON.stringify(requestData));
  //     };

  //     // Initial request on connection open
  //     sendRequest();

  //     console.log("Collecting data from WebSocket server");

  //     // Periodically send request every 1 second
  //     setInterval(sendRequest, 1000);
  //   };

  //   ws.onmessage = (event) => {
  //     console.log("Message from server:", event.data);
  //     const message = JSON.parse(event.data);

  //     if (message.type === "areaData") {
  //       message.data.forEach((element) => {
  //         console.log(element.percentage);
  //         switch (element.area) {
  //           case "Sumbagut":
  //             if (element.percentage <= 100) {
  //               console.log("Sumbagut percentage:", element.percentage);
  //               setPercentageSumbagut(element.percentage);
  //             } else {
  //               setPercentageSumbagut(100);
  //             }
  //             break;
  //           case "Sumbagsel":
  //             if (element.percentage <= 100) {
  //               console.log("Sumbagsel percentage:", element.percentage);
  //               setPercentageSumbagsel(element.percentage);
  //             } else {
  //               setPercentageSumbagsel(100);
  //             }
  //             break;
  //           case "Jabotabek 1":
  //             if (element.percentage <= 100) {
  //               console.log("Jabotabek 1 percentage:", element.percentage);
  //               setPercentageJabotabek1(element.percentage);
  //             } else {
  //               setPercentageJabotabek1(100);
  //             }
  //             break;
  //           case "Jabotabek 2":
  //             if (element.percentage <= 100) {
  //               console.log("Jabotabek 2 percentage:", element.percentage);
  //               setPercentageJabotabek2(element.percentage);
  //             } else {
  //               setPercentageJabotabek2(100);
  //             }
  //             break;
  //           case "Jawa Barat":
  //             if (element.percentage <= 100) {
  //               console.log("Jawa Barat percentage:", element.percentage);
  //               setPercentageJawaBarat(element.percentage);
  //             } else {
  //               setPercentageJawaBarat(100);
  //             }
  //             break;
  //           case "Jawa Tengah":
  //             if (element.percentage <= 100) {
  //               console.log("Jawa Tengah percentage:", element.percentage);
  //               setPercentageJawaTengah(element.percentage);
  //             } else {
  //               setPercentageJawaTengah(100);
  //             }
  //             break;
  //           case "Jawa Timur":
  //             if (element.percentage <= 100) {
  //               console.log("Jawa Timur percentage:", element.percentage);
  //               setPercentageJawaTimur(element.percentage);
  //             } else {
  //               setPercentageJawaTimur(100);
  //             }
  //             break;
  //           case "BNT":
  //             if (element.percentage <= 100) {
  //               console.log("BNT percentage:", element.percentage);
  //               setPercentageBNT(element.percentage);
  //             } else {
  //               setPercentageBNT(100);
  //             }
  //             break;
  //           case "Kalimantan":
  //             if (element.percentage <= 100) {
  //               console.log("Kalimantan percentage:", element.percentage);
  //               setPercentageKalimantan(element.percentage);
  //             } else {
  //               setPercentageKalimantan(100);
  //             }
  //             break;
  //           case "Pasima":
  //             if (element.percentage <= 100) {
  //               console.log("Pasima percentage:", element.percentage);
  //               setPercentagePasima(element.percentage);
  //             } else {
  //               setPercentagePasima(100);
  //             }
  //             break;
  //           case "Sultan":
  //             if (element.percentage <= 100) {
  //               console.log("Sultan percentage:", element.percentage);
  //               setPercentageSultan(element.percentage);
  //             } else {
  //               setPercentageSultan(100);
  //             }
  //             break;
  //           case "Head Office":
  //             if (element.percentage <= 100) {
  //               console.log("Head Office percentage:", element.percentage);
  //               setPercentageHeadOffice(element.percentage);
  //             } else {
  //               setPercentageHeadOffice(100);
  //             }
  //             break;

  //           default:
  //             break;
  //         }
  //       });
  //     }
  //   };

  //   // Handle WebSocket error event
  //   ws.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //     // Handle any errors, such as displaying an error message
  //   };

  //   // Handle WebSocket connection close event
  //   ws.onclose = () => {
  //     console.log("WebSocket connection closed.");
  //   };
  // }, []);

  useEffect(() => {
    // Initialize WebSocket
    const ws = new WebSocket("wss://adira-api.midyear.mahakreasi.com");

    const sendRequest = () => {
      // Prepare the request data object
      const requestData = {
        type: "getAreaData",
        data: {},
      };

      // Send the request data to the server
      ws.send(JSON.stringify(requestData));
    };

    ws.onopen = () => {
      console.log("Connected to WebSocket server");

      // Initial request on connection open
      sendRequest();

      console.log("Collecting data from WebSocket server");

      // Periodically send request every 1 second
      const intervalId = setInterval(sendRequest, 1000);

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === "areaData") {
        setDataArea(message.data.data);
        // message.data.data.forEach((element) => {
        //   switch (element.area) {
        //     case "SUMBAGUT":
        //       if (element.percentage <= 100) {
        //         setPercentageSumbagut(element.percentage);
        //       } else {
        //         setPercentageSumbagut(100);
        //       }
        //       break;
        //     case "SUMBAGTENG":
        //       if (element.percentage <= 100) {
        //         setPercentageSumbagteng(element.percentage);
        //       } else {
        //         setPercentageSumbagteng(100);
        //       }
        //       break;
        //     case "SUMBAGSEL":
        //       if (element.percentage <= 100) {
        //         setPercentageSumbagsel(element.percentage);
        //       } else {
        //         setPercentageSumbagsel(100);
        //       }
        //       break;
        //     case "JABOTABEK 1":
        //       if (element.percentage <= 100) {
        //         setPercentageJabotabek1(element.percentage);
        //       } else {
        //         setPercentageJabotabek1(100);
        //       }
        //       break;
        //     case "JABOTABEK 2":
        //       if (element.percentage <= 100) {
        //         setPercentageJabotabek2(element.percentage);
        //       } else {
        //         setPercentageJabotabek2(100);
        //       }
        //       break;
        //     case "JAWA BARAT":
        //       if (element.percentage <= 100) {
        //         setPercentageJawaBarat(element.percentage);
        //       } else {
        //         setPercentageJawaBarat(100);
        //       }
        //       break;
        //     case "JAWA TENGAH":
        //       if (element.percentage <= 100) {
        //         setPercentageJawaTengah(element.percentage);
        //       } else {
        //         setPercentageJawaTengah(100);
        //       }
        //       break;
        //     case "JAWA TIMUR":
        //       if (element.percentage <= 100) {
        //         setPercentageJawaTimur(element.percentage);
        //       } else {
        //         setPercentageJawaTimur(100);
        //       }
        //       break;
        //     case "BNT":
        //       if (element.percentage <= 100) {
        //         setPercentageBNT(element.percentage);
        //       } else {
        //         setPercentageBNT(100);
        //       }
        //       break;
        //     case "KALIMANTAN":
        //       if (element.percentage <= 100) {
        //         setPercentageKalimantan(element.percentage);
        //       } else {
        //         setPercentageKalimantan(100);
        //       }
        //       break;
        //     case "PASIMA":
        //       if (element.percentage <= 100) {
        //         setPercentagePasima(element.percentage);
        //       } else {
        //         setPercentagePasima(100);
        //       }
        //       break;
        //     case "SULTAN":
        //       if (element.percentage <= 100) {
        //         setPercentageSultan(element.percentage);
        //       } else {
        //         setPercentageSultan(100);
        //       }
        //       break;
        //     case "HEAD OFFICE":
        //       if (element.percentage <= 100) {
        //         setPercentageHeadOffice(element.percentage);
        //       } else {
        //         setPercentageHeadOffice(100);
        //       }
        //       break;
        //     default:
        //       break;
        //   }
        // });
      }
    };

    // Handle WebSocket error event
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      // Handle any errors, such as displaying an error message
    };

    // Handle WebSocket connection close event
    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    // Clean up WebSocket on component unmount
    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="w-screen h-screen flex items-end justify-center py-7 px-24">
      <img
        className="absolute top-0 left-0 z-0 w-full h-full object-cover"
        src={Background}
        alt=""
      />
      {/* <img
        className="absolute top-[-8px] left-0 w-16 m-4"
        src={Grofit}
        alt=""
      />
      <img className="absolfullute top-0 right-0 w-16 m-4" src={Adira} alt="" /> */}
      <div className="w-full h-full z-10 relative space-y-4">
        <div className="bg-black bg-opacity-30 shadow-md p-4 flex items-center justify-center">
          <h2 className="text-xl font-semibold mb-2 text-gray-100 uppercase">
            <img className="w-[1000px] ml-5" src={StatistikKehadiran} alt="" />
          </h2>
          {/* <img className="w-14 mr-5" src={LogoAdira} alt="Placeholder" /> */}
        </div>
        <div className="">
          {dataArea.map((element) => (
            <div className="bg-black bg-opacity-30 shadow-md p-4 space-x-16 flex">
              <div className="w-full space-y-3">
                <div className="flex items-center space-x-5">
                  <p className="w-96 pl-6 text-2xl text-gray-100 uppercase font-racesport">
                    {element.area}
                  </p>
                  <div className="w-full bg-black">
                    <div
                      className="progress-bar bg-yellow-400 font-magr blur-shadow p-1.5 text-center text-2xl font-medium leading-none text-black"
                      style={{
                        width: `${element.percentage}%`,
                        transition: "width 1s ease-in-out",
                      }}
                    >
                      {element.percentage}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Statistic;
