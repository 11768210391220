import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const Maxperson = () => {
  const ws = new WebSocket("wss://adira-api.midyear.mahakreasi.com");
  const [areaData, setAreaData] = useState([]);

  // Use an object to store form data instead of multiple states
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create an array to store the data for each input
    const inputData = [];

    // Loop through areaData to get the corresponding form data
    areaData.forEach((area, index) => {
      inputData.push({
        id: index + 1,
        area: formData[`subject_${index}`] || area.area,
        qty: formData[`qty_${index}`] || area.qty,
        max_capacity: formData[`max_capacity_${index}`] || area.max_capacity,
      });
    });

    console.log(inputData);

    // Prepare data object
    const data = {
      type: "inputArea",
      data: inputData,
    };

    // Send data as JSON string
    ws.send(JSON.stringify(data));

    ws.onmessage = (event) => {
      console.log("Message from server:", event.data);
      Swal.fire({
        title: "Success!",
        text: event.data.message,
        icon: "success",
      });

      ws.onopen = () => {
        const requestData = {
          type: "getAreaData",
          data: {},
        };
        ws.send(JSON.stringify(requestData));
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === "areaData") {
          setAreaData(message.data.data);
          // Update form data based on areaData
          const newFormData = {};
          areaData.forEach((area, index) => {
            newFormData[`subject_${index}`] = area.area;
            newFormData[`qty_${index}`] = area.qty;
            newFormData[`max_capacity_${index}`] = area.max_capacity;
          });
          setFormData(newFormData);
        }
      };
    };
  };

  useEffect(() => {
    ws.onopen = () => {
      const requestData = {
        type: "getAreaData",
        data: {},
      };
      ws.send(JSON.stringify(requestData));
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "areaData") {
        setAreaData(message.data.data);
        // Update form data based on areaData
        const newFormData = {};
        areaData.forEach((area, index) => {
          newFormData[`subject_${index}`] = area.area;
          newFormData[`qty_${index}`] = area.qty;
          newFormData[`max_capacity_${index}`] = area.max_capacity;
        });
        setFormData(newFormData);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };
  }, []);

  return (
    <div className="bg-gray-700 w-screen h-screen items-center space-y-3 p-4">
      <div className="bg-black bg-opacity-30 rounded-lg shadow-md p-4 flex items-center justify-center">
        <h2 className="text-xl font-semibold mb-2 text-gray-100 uppercase">
          Statistic Board
        </h2>
      </div>
      <div className="bg-black bg-opacity-30 rounded-lg shadow-md p-4">
        <form className="space-y-4" onSubmit={handleSubmit}>
          {areaData.map((subject, index) => (
            <div key={index}>
              <div className="flex space-x-2 mt-1 justify-center">
                <input
                  type="text"
                  name={`subject_${index}`}
                  placeholder="Subject"
                  value={formData[`subject_${index}`] || subject.area}
                  onChange={handleChange}
                  required
                  className="p-2 block w-1/5 rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-300 focus:ring-opacity-50"
                />
                <input
                  type="number"
                  name={`qty_${index}`}
                  placeholder="Quantity"
                  value={formData[`qty_${index}`] || subject.qty}
                  onChange={handleChange}
                  required
                  className="p-2 block w-1/5 rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-300 focus:ring-opacity-50"
                />
                <input
                  type="number"
                  name={`max_capacity_${index}`}
                  placeholder="Max Persons"
                  value={
                    formData[`max_capacity_${index}`] || subject.max_capacity
                  }
                  onChange={handleChange}
                  required
                  className="p-2 block w-1/5 rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-300 focus:ring-opacity-50"
                />
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-24 py-2 bg-yellow-300 text-black rounded-md font-medium hover:bg-yellow-400 transition duration-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Maxperson;
